import React, {ReactElement} from "react";
import Table from "../../components/table/table";
import Card from "../../components/card/card"
import Button from "../../components/button/button"
import {Link, useLoaderData} from "react-router-dom";
import {BackendAPI, DocumentPageData} from "../../services/rest";
import {toast} from 'react-toastify';


const api = new BackendAPI();

export function loader({params}: { params: any }) {
    return api.getDocuments(params.entity_code);
}

export default function DocumentsPage(): ReactElement {
    const {entity_name, documents} = useLoaderData() as DocumentPageData;

    const onGetDocument = (document_id: string, document_name: string) => toast.promise(
        api.getDocument(document_id, document_name),
        {
            pending: 'Accessing Document',
            success: 'Document Accessed',
            error: 'Access Failed'
        }
    )

    return <div style={{display: "flex", flexDirection: 'column', gap: 20}}>
        <Card>
            <div style={{fontSize: 23, fontWeight: 700, marginBottom: 20}}>
                <Link to={'/entities'} style={{textDecoration: 'none', color: "#0235a2"}}>Entities </Link>
                <span>/ {entity_name}</span>
            </div>
            <Table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Date Create</th>
                    <th>Access</th>
                </tr>
                </thead>
                <tbody>
                {
                    documents.map(({document_id, document_name, document_date, access_status}) => (
                        <tr key={document_id}>
                            <td>{document_name}</td>
                            <td>{document_date}</td>
                            <td>
                                <Button onClick={() => onGetDocument(document_id, document_name)}
                                        disabled={access_status === 'Pending'}>
                                    {access_status === 'Pending' ? "Pending Grant" : "Download"}
                                </Button>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        </Card>
    </div>
}